import React, { useState, useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl"

// s4n
import classNames from 'classnames';
// import kebabCase from "lodash.kebabcase"
import Link from "./../Link"
import Page from "./../../../model/node/page"
import ImageS4n from './../image'
// import ImageS4n from './../Image/index'
// import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
import useVersion from './../../../hooks/useVersion'
// import { Dropdown } from 'react-bootstrap'



const GROUPS = {
    all: `all`,
    website: `website`,
    ecommerce: `ecommerce`,
    dedicated: `dedicated`,
    mobile: `mobile`,
}

const translation = {
    [GROUPS.all]: {
        pl: 'Wszystkie',
        en: 'All',
    },
    [GROUPS.website]: {
        pl: 'Strona',
        en: 'Website',
    },
    [GROUPS.ecommerce]: {
        pl: 'Sklep',
        en: 'E-commerce',
    },
    [GROUPS.dedicated]: {
        pl: 'Dedykowana',
        en: 'Dedicated',
    },
    [GROUPS.mobile]: {
        pl: 'Mobilna',
        en: 'Mobile',
    },
}

// const translation = {
//     [GROUPS.all]: {
//         pl: 'Wszystkie',
//         en: 'All',
//     },
//     [GROUPS.website]: {
//         pl: 'Strona',
//         en: 'Website',
//     },
//     [GROUPS.website]: {
//         pl: 'Sklep',
//         en: 'E-commerce',
//     },
//     [GROUPS.dedicated]: {
//         pl: 'Dedykowana',
//         en: 'Dedicated',
//     },
//     [GROUPS.mobile]: {
//         pl: 'Mobilna',
//         en: 'Mobile',
//     },
// }

const realizations = [
    // {
    //     name: `www.paetzdental.de`,
    //     img: `custom/portfolio/paetzdental.de_.jpg`,
    //     url: `https://www.paetzdental.de/`,
    // },
    {
        name: `www.heuresis.pl`,
        img: `custom/portfolio/heuresis.pl_.jpeg`,
        url: `https://www.heuresis.pl/`,
        groups: [GROUPS.website, GROUPS.ecommerce, GROUPS.dedicated, GROUPS.mobile],
    },
    {
        name: `www.midimedia.nl`,
        img: `custom/portfolio/midimedia.nl_.jpg`,
        url: `https://www.midimedia.nl/`,
        groups: [GROUPS.ecommerce],
    },
    {
        name: `www.lebegesund.de`,
        img: `custom/portfolio/lebegesund.de__0.jpg`,
        url: `https://www.lebegesund.de/`,
        groups: [GROUPS.ecommerce],
    },
    {
        name: `www.mauertrockenlegungvoninnen-wien.at`,
        img: `custom/portfolio/mauertrockenlegungvoninnen-wien.at_.jpg`,
        url: `https://www.mauertrockenlegungvoninnen-wien.at/`,
        groups: [GROUPS.website, GROUPS.ecommerce],
    },
    {
        name: `www.montredo.com`,
        img: `custom/portfolio/montredo.com_.jpg`,
        url: `https://www.montredo.com/`,
        groups: [GROUPS.website, GROUPS.ecommerce],
    },
    {
        name: `www.mellowboards.com`,
        img: `custom/portfolio/mellowboards.com_.jpg`,
        url: `https://www.mellowboards.com/`,
        groups: [GROUPS.website, GROUPS.ecommerce],
    },
    {
        name: `www.ppdetektyw.pl`,
        img: `custom/portfolio/ppdetektyw.pl_.jpg`,
        url: `https://www.ppdetektyw.pl/`,
        groups: [GROUPS.website, GROUPS.mobile],
    },
    {
        name: `www.akwojtys.pl`,
        img: `custom/portfolio/akwojtys.pl_.jpg`,
        url: `https://www.akwojtys.pl/`,
        groups: [GROUPS.website],
    },
    // {
    //     name: `www.healthimpression.pl`,
    //     img: `custom/portfolio/`,
    //     url: `https://www.healthimpression.pl/`,
    // },
    {
        name: `www.ncttech.at`,
        img: `custom/portfolio/ncttech.at_.jpg`,
        url: `https://www.ncttech.at/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.zoltarpark.pl`,
        img: `custom/portfolio/zoltarpark.pl__0.jpg`,
        url: `https://www.zoltarpark.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.bodome-group.com`,
        img: `custom/portfolio/bodome-group.com_.jpg`,
        url: `https://www.bodome-group.com/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.insightbv.com`,
        img: `custom/portfolio/insightbv.com_.jpg`,
        url: `https://www.insightbv.com/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.dhatus.pl`,
        img: `custom/portfolio/dhatus.pl_.jpg`,
        url: `https://www.dhatus.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.nadzorykrakow.com.pl`,
        img: `custom/portfolio/nadzorykrakow.com_.pl_.jpg`,
        url: `https://www.nadzorykrakow.com.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.energo-mont.pl`,
        img: `custom/portfolio/energo-mont.pl_.jpg`,
        url: `http://www.energo-mont.pl/`,
        groups: [GROUPS.website],
    },
    // {
    //     name: `www.wierzytelnoscikrakow.pl`,
    //     img: `custom/portfolio/`,
    //     url: `https://www.wierzytelnoscikrakow.pl/`,
    //     groups: [GROUPS.website],
    // },
    {
        name: `www.4-it.eu`,
        img: `custom/portfolio/582b99fa7f9e8.jpg`,
        url: `https://www.4-it.eu/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.ondis24.com`,
        img: `custom/portfolio/5700dd3b1fab6.png`,
        url: `https://www.ondis24.com/`,
        groups: [GROUPS.website, GROUPS.ecommerce],
    },
    {
        name: `www.logisticcity.pl`,
        img: `custom/portfolio/56edd54fc160f.jpg`,
        url: `https://www.logisticcity.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.hedpes.pl`,
        img: `custom/portfolio/582b99ef9eca8.jpg`,
        url: `https://www.hedpes.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.fsg.pl`,
        img: `custom/portfolio/56edd726b31c9.png`,
        url: `http://www.fsg.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.newhomes.at`,
        img: `custom/portfolio/56edd7d1c1e9f.png`,
        url: `http://www.newhomes.at/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.osiedlepodlesne.pl`,
        img: `custom/portfolio/56edd7533b998.png`,
        url: `http://www.osiedlepodlesne.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.mim.poznan.pl`,
        img: `custom/portfolio/56edd89e767fd.jpg`,
        url: `http://www.mim.poznan.pl/`,
        groups: [GROUPS.website],
    },
    {
        name: `www.lhinvest.pl`,
        img: `custom/portfolio/56edd873adb5c.jpg`,
        url: `http://www.lhinvest.pl/`,
        groups: [GROUPS.website],
    },
];



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const currentBuildVersion = useVersion();

    const limit = props.limit || undefined;
    const header = props.header || false;

    const portfolioNodeId = 17;
    const nodePortfolio = Page.getItemByNodeId(portfolioNodeId, intl.locale);

    const stateInit = {
        group: `all`,
        shuffle: null,
    }
    const [state, setState] = useState(stateInit);

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {

        const isBrowser = typeof window !== 'undefined'
        if (!isBrowser) {
            return false
        }

        // loadScripts();
    }, []);
    // }, [intl.locale]);

    // const loadScripts = async () => {
    //     if (typeof window.Shuffle === `undefined`) {
    //         await loadScript(withPrefix(`/kross/plugins/shuffle/shuffle.min.js${currentBuildVersion}`), { inBody: true });
    //     }      

    //     if (null !== state.shuffle) {
    //         return true;
    //     }

    //     const Shuffle = window.Shuffle;
    //     const myShuffle = new Shuffle(document.querySelector(`.shuffle-wrapper`), {
    //         itemSelector: `.shuffle-item`,
    //         buffer: 1,
    //     });

    //     setState({
    //         shuffle: myShuffle,
    //     });
    // }

    /*
        @see: https://medium.com/@florenceliang/javascript-event-delegation-and-event-target-vs-event-currenttarget-c9680c3a46d1
        Basically, events bubble by default so the difference between the two is:
        target: is the element that triggered the event (e.g., the user clicked on <a> ( <a> → <li> → <ul> → <div> → <body> ) target is <a> )
        currentTarget: is the element that the event listener is attached to: document.addEventListener(“click”, (e) => {)); currentTarget is document
    */
    const handleClick = (event) => {
        const input = event.currentTarget;
        const group = input.value;

        setState({
            ...state,
            group: group,
        });

        state.shuffle.filter(group);
    }

    const ButtonGroup = Object.keys(GROUPS).map(group => {
        const oClassNames = classNames({
            [`btn btn-sm btn-primary`]: true,
            [`active`]: `all` === group,
        });

        return (
            <label key={`shuffle-item-${group}`} className={oClassNames}>
                <input type="radio" name="shuffle-filter" value={group} onClick={handleClick} />
                {translation[group][intl.locale]}
                {/* {GROUPS[group]} */}
            </label>
        )
    })

    /**
     * @see: https://vestride.github.io/Shuffle/shuffle-with-react
     */
    return (
        <React.Fragment>

            <section className="section s4n-portfolio">
                <div className="container">

                    {header && 
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="section-title">{nodePortfolio ? nodePortfolio.title : null}</h2>
                            </div>
                        </div>
                    }

                    {!header && 
                        <div className="row mb-5">
                            <div className="col d-flex justify-content-center">

                                {/* {state.group} */}

                                {/* <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">Dropdown Button</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    {Object.keys(GROUPS).map(group => {
                                        const oClassNames = classNames({
                                            [`btn btn-sm btn-primary _col-xs-12`]: true,
                                            [`active`]: state.group === group,
                                        });

                                        return (
                                            <Dropdown.Item 
                                                // href="#/action-1" 
                                                key={`shuffle-item-${group}`} 
                                                _className={oClassNames}
                                            >
                                                {GROUPS[group]}
                                            </Dropdown.Item>
                                        )
                                    })}
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                {/* hide on screens wider than lg, @see: https://getbootstrap.com/docs/4.0/utilities/display/ */}
                                <div className="d-lg-none">
                                    <div className="btn-group-vertical btn-group-toggle" data-toggle="buttons" role="group" aria-label="Vertical button group">
                                        {ButtonGroup}
                                    </div>
                                </div>

                                {/* hide on screens smaller than lg, @see: https://getbootstrap.com/docs/4.0/utilities/display/ */}
                                <div className="d-none d-lg-block">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons" role="group" aria-label="Horizontal button group">
                                        {ButtonGroup}
                                    </div>
                                </div>

                            </div>
                        </div>
                    }

                    <div className="row shuffle-wrapper     justify-content-center">
                        {realizations.map((realization, index) => {
                            if (`undefined` !== typeof limit && index >= limit) {
                                return null;
                            }

                            return (
                                <div key={`portfolio-item-${index}`} className="col-12 col-sm-6 col-lg-4 mb-4 shuffle-item" data-groups={JSON.stringify(realization.groups)}>
                                    <div className="position-relative rounded hover-wrapper">
                                        {/* <img src="images/portfolio/item-1.png" alt="portfolio-image" className="img-fluid rounded w-100" /> */}
                                        <ImageS4n src={realization.img} alt={realization.name} className="img-fluid rounded w-100" _maxWidth="103px" />
                                        <div className="hover-overlay">
                                            <div className="hover-content">
                                                <a className="btn btn-light btn-sm" href={realization.url} target="_blank" rel="noreferrer">{realization.name}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {`undefined` !== typeof limit && 
                        <div className="row">
                            <div className="col-12 text-center">
                                <Link to={portfolioNodeId} className="btn btn-primary"></Link>
                            </div>
                        </div>
                    }

                </div>
            </section>
        </React.Fragment>
    )
}

export default ComponentStateless